
import {defineComponent} from 'vue';
//@ts-ignore
import {Checkbox as CheckBoxDone} from '@vicons/ionicons5'
//@ts-ignore
import {Checkbox as CheckBoxTodo} from '@vicons/carbon'


export default defineComponent({
  name: 'Home',
  components: {
    CheckBoxDone, CheckBoxTodo
  },
  methods: {}
});
